<template>
  <div :id="item.name" class="game-item" :class="{ maintain: item.maintain }" @click="handleOpenGame(item)">
    <div class="item">
      <div class="game-item__thumbnail-wrapper">
        <div class="image-wrapper">
          <BaseImg :id="`${item?.name}-image`" :src="item?.img" :alt="item?.name" lazy class="game-item__thumbnail" />
        </div>
        <div v-if="jackpot(item.partner_game_id)" class="game-item__jackpot">
          <AnimatedNumber :number="jackpot(item.partner_game_id)" show-coin />
        </div>
      </div>
    </div>
    <BaseImg v-if="item.maintain" class="game-item__maintain" src="assets/images/components/common/tag-maintain.svg" />
  </div>
</template>

<script setup lang="ts">
import AnimatedNumber from '~/components/common/animate-number.vue'
import useNavigationGame from '~/composables/useNavigationGame'
import { useAppStore } from '~/store/app'
import BaseImg from '~/components/common/base-img.vue'
import { IGameItem } from '~/types/common'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)

const props = defineProps<{
  item: IGameItem
}>()

const jackpot = (gameid: string | undefined): number | undefined => {
  if (gameid) {
    return store.jackpot[gameid] ?? undefined
  }
  return undefined
}

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()

const handleOpenGame = (item: IGameItem): void => {
  navigationCheckLoggedInAndOpenGame(item)
}
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/hot-games/game-item.scss" />
